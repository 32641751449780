import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'modalInner']

  open(event) {
    const el = event.currentTarget
    const elImage = el.querySelector('img').cloneNode(true)
    elImage.style.height = `${window.innerHeight - 120}px`

    this.modalTarget.classList.toggle('hidden')
    this.modalInnerTarget.appendChild(elImage)
  }

  close() {
    this.modalTarget.classList.toggle('hidden')
    this.modalInnerTarget.innerHTML = ''
  }
}
