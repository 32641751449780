import Turbolinks from 'turbolinks'
import SweetScroll from 'sweet-scroll'
import Swiper, { Navigation, Pagination } from 'swiper'

import 'typeface-source-sans-pro'
import 'swiper/swiper-bundle.css'

import 'controllers'

require.context('../images', true)
require('../css/application.scss')

Turbolinks.start()

Swiper.use([Navigation, Pagination])

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('[data-js-header]')) {
    const headerHeight = document.querySelector('[data-js-header]').offsetHeight
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`)
  }

  new SweetScroll()

  new Swiper('[data-js-side-gallery]', {
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: '[data-js-side-gallery-next]',
      prevEl: '[data-js-side-gallery-prev]',
    },
  })

  new Swiper('[data-js-banner-gallery]', {
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: '[data-js-banner-gallery-next]',
      prevEl: '[data-js-banner-gallery-prev]',
    },
    pagination: {
      el: '[data-js-banner-gallery-pagination]',
      clickable: true,
    },
  })

  let links = document.links
  for (let i = 0, linksLength = links.length; i < linksLength; i++) {
    if (links[i].hostname !== window.location.hostname || links[i].href.includes('active_storage')) {
      links[i].target = '_blank'
      links[i].rel = 'noreferrer'
    }
  }
})

// Makes the initMap function Google's API requires
// available in Stimulus controllers
window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}
