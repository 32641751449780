import { Controller } from 'stimulus'
import MarkerClusterer from '@google/markerclustererplus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    if (typeof google !== 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    let map
    let bounds = new google.maps.LatLngBounds()
    let markers = []
    let startPosition = { lat: this.locations[0].lat, lng: this.locations[0].lng }

    map = new google.maps.Map(this.containerTarget, {
      center: startPosition,
      zoom: 5,
    })

    for (let location of this.locations) {
      let marker
      let position = { lat: location.lat, lng: location.lng }
      let contentString = this.generateContent(location)
      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      marker = new google.maps.Marker({
        position: position,
        map: map,
        title: location.company,
      })

      marker.addListener('click', function () {
        infowindow.open(map, marker)
      })

      markers.push(marker)

      bounds.extend(position)
      marker.setMap(map)
    }

    map.fitBounds(bounds)
    map.setZoom(5)
    new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    })
  }

  generateContent(location) {
    if (location.link) {
      return `<div id='content' class='prose p-2 max-w-sm'>
        <h3>${location.company}</h3>
        <address class="not-italic">${location.address}</address>
        <a href="${location.link}" target="_blank">${location.link}</a>
      </div>`
    } else {
      return `<div id='content' class='prose p-2 max-w-sm'>
        <h3>${location.company}</h3>
        <address class="not-italic">${location.address}</address>
      </div>`
    }
  }

  get locations() {
    return JSON.parse(this.data.get('locations'))
  }
}
